import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { readUserProfile } from '@/store/main/getters';
import { dispatchUpdateUserProfile } from '@/store/main/actions';
let UserProfileEdit = class UserProfileEdit extends Vue {
    constructor() {
        super(...arguments);
        this.valid = true;
        this.fullName = '';
        this.email = '';
    }
    created() {
        const userProfile = readUserProfile(this.$store);
        if (userProfile) {
            this.fullName = userProfile.full_name;
            this.email = userProfile.email;
        }
    }
    get userProfile() {
        return readUserProfile(this.$store);
    }
    reset() {
        const userProfile = readUserProfile(this.$store);
        if (userProfile) {
            this.fullName = userProfile.full_name;
            this.email = userProfile.email;
        }
    }
    cancel() {
        this.$router.back();
    }
    async submit() {
        if (this.$refs.form.validate()) {
            const updatedProfile = {};
            if (this.fullName) {
                updatedProfile.full_name = this.fullName;
            }
            if (this.email) {
                updatedProfile.email = this.email;
            }
            await dispatchUpdateUserProfile(this.$store, updatedProfile);
            this.$router.push('/main/profile');
        }
    }
};
UserProfileEdit = __decorate([
    Component
], UserProfileEdit);
export default UserProfileEdit;
